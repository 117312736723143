import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AccountAppsGQL } from '@hxp/graphql';
import { HyAuthService } from '@hyland/ui';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private readonly hyAuthService: HyAuthService,
    private readonly accountAppsService: AccountAppsGQL,
    private readonly router: Router,
    private readonly location: Location,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const hasOnboardingAccess$ = this.accountAppsService.watch().valueChanges.pipe(
      filter((resp) => !!resp.data),
      map((resp) => {
        return resp.data.currentUser.accountApps.map((app) => app.appKey).includes('onboarding');
      }),
    );

    return this.hyAuthService.authenticated$.pipe(
      switchMap((isAuthenticated) => {
        if (!isAuthenticated) {
          void this.hyAuthService.login({ state: { route: state.url } });
          return of(false);
        }

        return hasOnboardingAccess$.pipe(
          map((hasOnboardingAccess) => {
            if (!hasOnboardingAccess) {
              this.redirectToUnauthorized(state);
            }

            return hasOnboardingAccess;
          }),
          catchError(() => {
            this.redirectToUnauthorized(state);
            return of(false);
          }),
        );
      }),
    );
  }

  private redirectToUnauthorized(state: RouterStateSnapshot) {
    void this.router.navigate(['/unauthorized'], { skipLocationChange: true }).then(() => {
      this.location.replaceState(state.url);
    });
  }
}
