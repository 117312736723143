import { AnalyticsConfig, defaultAnalyticsConfig } from '../services/analytics/analytics';
import { autoImplement } from '../types';

export interface ConfigurationOptions {
  useFakeAuth: boolean;
  commitHash: string;
  production: boolean;
  backendHost: string;
  graphqlApi: string;
  auditExportEndpointPath: string;
  termsConditionsUrl: string;
  privacyPolicyUrl: string;
  usagePolicyUrl: string;
  analytics: AnalyticsConfig;
  helpUrl?: string;
}

export class Configuration extends autoImplement<ConfigurationOptions>() {
  constructor(config?: Partial<ConfigurationOptions>) {
    super();

    this.useFakeAuth = config?.useFakeAuth || false;
    this.commitHash = config?.commitHash || '';
    this.production = config?.production || true;
    this.backendHost = config?.backendHost || '';
    this.graphqlApi = config?.graphqlApi || '';
    this.auditExportEndpointPath = config?.auditExportEndpointPath || '';
    this.termsConditionsUrl = config?.termsConditionsUrl || '';
    this.privacyPolicyUrl = config?.privacyPolicyUrl || '';
    this.usagePolicyUrl = config?.usagePolicyUrl || '';
    this.analytics = config?.analytics || defaultAnalyticsConfig;
    this.helpUrl = config?.helpUrl ?? '';
  }
}
